:root {
    --primary: #6D767D;
    --secondary: #dadfe3;
    --background:  #fdfaf3;
    --black: #000000;
    --white: #ffffff;
    --bg-second: #9b9797;
}
body {
    font-family: "AmsiPro";
    background: var(--background);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "AmsiPro";
    src: url("./assets/fonts/AmsiPro-Bold.otf") format("opentype");
    font-weight: bold;
}

@font-face {
    font-family: "AmsiPro";
    src: url("./assets/fonts/AmsiPro-Regular.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "AmsiPro";
    src: url("./assets/fonts/AmsiPro-Light.otf") format("opentype");
    font-weight: lighter;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#footer-page {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.container-fluid {
    padding: 0px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.bg-footer {
    /* background-image: url("/images/footerup.png"); */
    background-image: url("./assets/images/footerup.png");
    background-repeat: no-repeat;
    margin-top: 2rem;
}

.footer {
    position: relative;
    top: 10px;
    background: var(--secondary);
    text-align: center;
}

.error {
    color: red;
}

.success {
    color: green;
}

input {
    display: block;
}

.nav-pills .nav-link.active{
    background-color: var(--primary);
    color: #fff;   
}

.nav-pills .nav-link{
    color: #000;
}

.table {
    --bs-table-bg: none;
}

.dropdown-item:active {
    background-color: var(--primary);
}

ul {
    padding-left: 20px;
}

::placeholder {
    color: #fff;
}

::placeholder {
    color: red;
    opacity: 1; /* Firefox */
}
.pre-wrap{
    white-space: pre-wrap;
}
.flag-logo{
    margin: 0px 2px 0px 2px;
   width: 25px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
.eventbtn{
    
    margin-top: 5px;
}

/* Date Picker custom css */
.text-center{
    text-align: center;
  }
  
  .custom-date-picker-container {
    width: 300px; 
    font-family: Arial, sans-serif; 
  }
  
  .custom-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; 
    border-bottom: 1px solid #ccc; 
  }
  
  .header-text {
    margin: 0 15px; 
    font-weight: bold;
    font-size: 15px;
  }
  
  .arrow-button {
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 1.2em;
    color: gray;
  }
  
  .arrow-button:hover {
    background-color: var(--bg-second) !important;
  }

  .react-datepicker-popper {
    z-index:5;
    line-height: 1;
}
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: var(--bg-second) !important;
    color: #fff;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: var(--bg-second) !important;
    color: white;
    font-weight: bold;
  }
  
  .react-datepicker__close-icon::after {
    background-color: var(--bg-second) !important;
  }
  
  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
    background-color: var(--bg-second) !important;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: var(--bg-second) !important;
  }
  .cursor{
    cursor:pointer;
  }

  .form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--secondary);
}

.form-switch .form-check-input {
    background-position: left center;
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e);
}

  /* date picket custom css in all forms */
.react-datepicker-wrapper{
    width: 100% !important;
}

.react-datepicker__input-container> input{
  width: 100% !important;
  border-radius: 5px !important;
  background-color: transparent !important;
  border: 2px solid rgb(221, 221, 219) !important;
}

.custom-calender input{
  height: 30px !important;
}

#date {
  border: none !important;
}

/* Profile Phone Input filed css */
.react-international-phone-input{
  width: 100%;
}

/* Pagination CSS */

.active>.page-link, .page-link.active {
  z-index: 3;
  background-color: var(--primary);
  border-color: var(--primary);
}